import {CircularProgress, InputAdornment, TextField} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {
  masterCard,
  iconNotifySuccess,
  visa,
  errorBigIcon,
  Checkmark,
  pen,
  errorCrossIcon
} from '../../../../assets/icons/icons';
import { Button } from '../../../../components/StyledComponents';
import { ECardTypes } from '../../../../models/consts';
import {ICouponDiscount, IError} from '../../../../models/inner-models';
import {
  getStripeDefaultPaymentMethod, getUser,
  setStripeDefaultPaymentMethod, getCoupon
} from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isAuthorized, stripePaymentMethod } from '../../../../store/selectors';
import './style.scss';
import {useNavigate} from "react-router-dom";

const useOptions = () => {
  return {
    style: {
      base: {
        color: '#000000',
        fontSize: '13px',
        fontWeight: 300,
        opacity: 0.7,

        '::placeholder': {
          color: '#D1D5DB',
          fontSize: '13px',
          fontWeight: 300,
          opacity: 0.7,
        },
      },
      invalid: {
        color: '#FC5640',
      },
    },
    postalCode: true
  };
};

interface ICardFormProps {
  onClose?: () => void;
  buySub?: ()=>void;
  setIsUpdate: (boolean)=>void;
  isUpdate?: boolean;
  isUpdateInfo?: boolean;
  disableBtn?: boolean;
  clientSecret: string | undefined;
  totalPrice: number;
  setCouponCode:(string)=>void
  setCouponDiscountObj:(couponDiscountObj : ICouponDiscount)=>void
  setCouponLoad:(boolean)=>void
  couponCode: string;
  couponDiscountObj: ICouponDiscount;
  appName: string;
}

const CardForm: FunctionComponent<ICardFormProps> = ({
                                                       buySub, onClose,
                                                       disableBtn, isUpdate, isUpdateInfo,
                                                       setIsUpdate, clientSecret,
                                                       totalPrice,couponCode,
                                                       setCouponCode, setCouponDiscountObj,
                                                       setCouponLoad , couponDiscountObj , appName
}: ICardFormProps) => {
  const stripe = useStripe();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [addCard, setAddCard] = useState<boolean>(false);
  const [isCardUpdating, setIsCardUpdating] = useState<boolean>(false);
  const options = useOptions();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const defaultPaymentMethod = useAppSelector(stripePaymentMethod);
  const isAuthed = useAppSelector(isAuthorized);

  useEffect(() => {
    const validCuopon = async () =>  await checkCouponValid()

    if(couponCode.length > 0){
      validCuopon()
    }

    const fetchData = async () => {
      try {
        await dispatch(getStripeDefaultPaymentMethod()).unwrap();
      } catch (e) {
        console.log(e);
      }
    };

    if (!isAuthed) {
      return;
    }

    fetchData();
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      if (addCard) {
        setIsCardUpdating(true)
      } else {
        if (clientSecret) {
          setIsCardUpdating(true)
        }
        setIsUpdate(true);
      }

      if (addCard || !defaultPaymentMethod || clientSecret) {
        const cardElement = elements.getElement(CardNumberElement);
        if (!cardElement) {
          return;
        }

        if (!clientSecret) {
          const {paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
          });

          if (!paymentMethod) {
            setIsUpdate(false);
            setIsCardUpdating(false)

            await Swal.fire({
              imageUrl: errorBigIcon,
              title: translate('notifications.titles.error'),
              text: translate('notifications.payment.incorrect_card'),
            });
            return;
          }

          await dispatch(setStripeDefaultPaymentMethod({
            id: paymentMethod?.id as string,
            card: paymentMethod?.card?.brand as string,
            last_4: Number(paymentMethod?.card?.last4),
          })).unwrap();
          // setIsUpdate(false);

          setIsCardUpdating(false);
          setAddCard(false)

          // await Swal.fire({
          //   toast: true,
          //   position: 'top-end',
          //   timerProgressBar: true,
          //   showConfirmButton:false,
          //   showCloseButton: true,
          //   imageUrl: toastSuccess,
          //   timer: 3000,
          //   title: translate('notifications.titles.success'),
          //   text: translate('notifications.payment.success_card_change'),
          // });
        } else if (clientSecret) {
          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          });

          await new Promise<void>((resolve) => setTimeout(async function () {
            await dispatch(getUser()).unwrap();
            resolve();
          }, 5000));

          if (result.error) {
            setIsUpdate(false);
            setIsCardUpdating(false)
            throw new Error(result.error.message || translate('notifications.payment.stripe_error'));
          }

          await Swal.fire({
            title: translate('notifications.titles.success'),
            text: appName === 'jira' ? translate('notifications.payment.success_pay_jira') : translate('notifications.payment.success_pay'),
            imageUrl: iconNotifySuccess,
          });

          // redirecting for platform name
          navigate(`/`);
        }
      }

      if (buySub && !clientSecret && !addCard) {
        buySub()
      }
    } catch (err) {
      const error = err as IError;
      setIsUpdate(false);
      setIsCardUpdating(false)

      Swal.fire({
        title: translate('notifications.titles.error'),
        text: !!error.error ? error.error : 'Your card was declined for making repeated attempts or you have insufficient funds.',
        imageUrl: errorBigIcon,
      });
    }
  };

  const checkCouponValid = async ()=>{
    try{
      setCouponLoad(true);
      if(couponCode?.length > 0){
      const response = await dispatch(getCoupon({ promotion_code : couponCode })).unwrap()
        if(response.status === 'success'){
        setCouponDiscountObj({ amountOff:+response.amountOff || 0,currency: response.currency, percentOff: +response.percentOff || 0, valid: true
        })
        }else {
          setCouponDiscountObj({ amountOff:0,currency: null, percentOff: 0, valid: false
          })
        }
      }else{
        setCouponDiscountObj({ amountOff:0,currency: null, percentOff: 0, valid: null
        })
      }
    }catch (e){
      console.log(e)
    } finally {
      setCouponLoad(false);
    }
  }
  const isInvalidCoupon = couponDiscountObj.valid === false && couponCode?.length > 0

  return (
    <div className="card-block">
      {defaultPaymentMethod && !clientSecret && !addCard && (
        <div className="block-label">
          <div className='pref-card-title'>
            {translate('forms.common.payment_method_pref')}
          </div>
            <div className="payment-method">
              <div className="card-element">
                <img className="card-icon"
                     src={defaultPaymentMethod?.cardType === ECardTypes.MASTERCARD ? masterCard : visa} loading="lazy"
                />
                **** **** **** {defaultPaymentMethod?.lastDigits }
              </div>
            </div>
          <small>{translate('forms.common.payment_method_pref_desc')}</small>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {(!defaultPaymentMethod || addCard || clientSecret) && (
          <>
            <label className="input-label">
              {translate('notifications.payment.card_number')}
              <CardNumberElement options={options} className="card-elem" />
            </label>
            <label className="input-label width-50">
              {translate('notifications.payment.expire')}
              <CardExpiryElement options={options} className="card-elem " />
            </label>
            <label className="input-label width-50">
              {translate('notifications.payment.cvc')}
              <CardCvcElement options={options} className="card-elem" />
            </label>
          </>
        )}
        <div>
        {defaultPaymentMethod && (!clientSecret && !addCard ?
            <Button
              variant="contained" color="secondary"
              className='newapp-back'
              onClick={()=>setAddCard(true)}
              mb={4}
              disabled={isUpdate}
            >
              <img src={pen} className="edit-icon" style={{marginRight: '4px'}} loading="lazy"/>
              Change Card
            </Button> :
            <>
              <Button
                variant="outlined" color="primary"
                className='newapp-back'
                onClick={()=>setAddCard(false)}
                mb={5}
                disabled={!!clientSecret || isCardUpdating}
              >
                Cancel
              </Button>
              <Button
                variant="contained" color="primary"
                className='newapp-back'
                type='submit'
                mb={5}
                ml={3}
                disabled={isCardUpdating}
              >
                {(isCardUpdating) &&
                  <div className="small-spinner-wrapper">
                    <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                  </div>
                }
                {clientSecret ? 'Update card and continue' : 'Save card'}
              </Button>
            </>
        )}
        </div>
        <label className={`coupon-input input-label `} >
          {translate('notifications.payment.coupon_code')}
          <TextField
           // error={isInvalidCoupon}
           disabled={isUpdateInfo}
           placeholder={'Optional coupon code'}
           value={couponCode}
           onChange={(event) => setCouponCode(event.target.value)}
           onBlur={() => checkCouponValid()}
           InputProps={{
             endAdornment: <InputAdornment position="end">
               {couponDiscountObj.valid && couponCode && couponCode.length>0 &&
                 <Checkmark  stroke={'#065F46'}/>
               }
               {isInvalidCoupon &&
                 <img src={errorCrossIcon}  className='checkout-error-coupon' loading="lazy"/>
               }
             </InputAdornment>,
           }}
          />
          {isInvalidCoupon && <label className="invalid-coupon"> {translate('notifications.payment.invalid_coupon_code')}</label>}
          {couponDiscountObj.valid && couponCode && couponCode.length>0 && (
            <label className="applied-coupon"> {translate('notifications.payment.applied_coupon_code')}</label>
          )}
        </label>
        {!!couponCode?.length && (
          <Button
            disabled={isUpdateInfo}
            variant="outlined" color="primary"
            className='newapp-back'
            onClick={checkCouponValid}
            mr={4}
          >
            Check coupon
          </Button>
        )}
        <div className="action-buttons">
          {onClose && (
            <Button
              disabled={isUpdate || isUpdateInfo || !!clientSecret}
              variant="outlined" color="primary"
              className='newapp-back'
              onClick={onClose}
              mr={4}
            >
              Back
            </Button>
          )}
          <Button
            disabled={!stripe || isUpdate || disableBtn || isUpdateInfo || addCard || !!clientSecret}
            variant="contained"
            color="primary"
            type="submit"
          >
            {(isUpdate) &&
              <div className="small-spinner-wrapper">
                <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
              </div>}
            {isUpdate ?
              translate('notifications.choices.processing') :
              translate('notifications.choices.buy_subscription')
            }
            {totalPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CardForm;
