// import { createGlobalStyle } from 'styled-components/macro';
import './styles/_fonts.scss';
import { GlobalStyleProps } from './types/styles';
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
    font-family: ${(props) => props.theme.typography.fontFamily};

    &.swal2-height-auto {
      height: 100%!important;
    }
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .page-title-h3, .MuiTypography-root.MuiTypography-h3 {
    font-weight: 700;
    font-size: 30px!important;
    line-height: 38px!important;
    color: #111827;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgb(230, 230, 230);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #9982f5;
    -webkit-box-shadow: inset 0 0 3px rgb(153, 130, 245);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: #9982f5;
  }

  body {
    scrollbar-face-color: #9982f5;
    scrollbar-track-color: #e6e6e6;
  }
`;

export const GlobalAuthStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    overflow-x: hidden;
  }

  body {
    background: linear-gradient(90deg, #684EE7 0%, #62D3E2 100%);;
    margin: 0;
    font-family: "Manrope Medium";
    overflow: hidden;
    height: auto;
    min-height: 100%;
  }
  
  body:has(.auth-signup-right) {
    background: linear-gradient(90deg, #684EE7 50%, #62D3E2 100%);
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiOutlinedInput-root { 
    &.Mui-focused fieldset {
      border-color: #6D4CF0!important;
    }
  }

  .MuiPaper-root {
    max-width: 520px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 32px;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 767px) {
      width: 90%;
      min-width: unset;
    }

    .auth-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: #111827;
      text-align: center;
      margin-bottom: 8px;
      font-family: "Manrope Regular";
    }

    .form-row {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #374151;
      display: flex;
      margin-bottom: 16px;
      flex-direction: column;

      &.active {
        cursor: pointer;
      }

      .label-block {
        font-weight: 500;
        width: 40%;
        line-height: 20px;
        font-family: "Manrope Medium";
        font-size: 14px;
        &.full-width {
          width: 100%;
        }
        @media (max-width: 767px) {
          width: 100%;
          line-height: 18px;
        }
      }

      .input-block {
        width: 70%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .MuiInputLabel-root {
        span {
          display: none;
        }
      }

    }

    .primary-button {
      color: white;
      background: #6D4CF0;
      border-color: #6D4CF0;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      font-family: "Manrope Medium";
     
      &[disabled] {
        opacity: 0.7;
      }

      &.outlined {
        border: 1px solid #D1D5DB;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background: none;
        color: #374151;
      }

      :hover {
        background: #5c3be0;
      }

      :active {
        background: #5c3be0;
      }
    }

    .secondary-button {
      background: white;
      color: #0064FF;
      margin-top: 5px;

      &.outlined {
        background: none;
        color: white;
        border: 1px solid white;
      }

      &.w-100 {
        width: 100%;
      }
    }
  }

  .brand-block {
    cursor: pointer;
    margin-top: 40px;

    .logo {
      width: auto;
      height: 28px;
    }
  }

  .first-arrow, .second-arrow {
    position: fixed;
    top: -25%;
    transform: scale(0.8);
    z-index: -1;
  }

  .first-arrow {
    left: 10%;
  }

  .second-arrow {
    right: -5%;
  }

  .third-arrow, .fourth-arrow {
    position: fixed;
    transform: scale(0.8);
    bottom: -45%;
    z-index: -1;

    @media (max-height: 700px) {
      display: none;
    }
  }

  .third-arrow {
    left: 0;
  }

  .fourth-arrow {
    right: 5%;
  }

  h2 {
    text-align: center;
  }

  .rights-reserved {
    opacity: 0.8;
  }

  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgb(230, 230, 230);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #9982f5;
    -webkit-box-shadow: inset 0 0 3px rgb(153, 130, 245);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: #9982f5;
  }

  body {
    scrollbar-face-color: #9982f5;
    scrollbar-track-color: #e6e6e6;
  }
`;


export default GlobalStyle;
